<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent
          class="pt-8"
          title="Article Thermal Characterization Tests"
          size="large"
        />
        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full md:pr-2">
            <TitleComponent
              class="py-8"
              title="Exudate Example"
              size="medium"
            />
            <a
              target="_blank"
              :href="
                require('../../assets/IPSeries/articleThermalStab-Go-min.png')
              "
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/IPSeries/articleThermalStab-Go-min.png"
              />
            </a>
          </div>
          <div class="w-full md:pl-2">
            <TitleComponent class="pt-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used to determine the reaction of articles when
              subjected to a mildly elevated temperature (75°C) for a specific
              period of time (48 hours). At the completion of the test, the
              article is examined for evidence of reaction. Any evidence of
              explosion, fire, damaged outer casing, dangerous exudation of
              explosive visible outside the casing, or a temperature rise
              greater than 3C above the oven temperature is criteria for a
              positive result (i.e. fails the test).
            </p>
            <p class="p-light mt-4">
              If an unfinished article fails this test, further testing is not
              required. The classification of that article is a 1.1, mass
              reaction hazard.
            </p>
            <TitleComponent class="pt-8" title="Equivalence" size="medium" />
            <p class="p-light">
              This test is similar to
              <router-link class="link" to="/un-article-thermal-stability"
                >Test 4 (a)</router-link
              >
              in the UN Manual of Tests and Criteria.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>
                      Test temperature and time of exposure to that temperature
                    </td>
                    <td>
                      Determine the stability of an article using exposure time
                      and temperatures that represents worst-case in-process
                      conditions and/or provides accelerated aging
                    </td>
                    <td></td>
                    <td>75°C for 48 hours</td>
                  </tr>
                  <tr>
                    <td>Test oven</td>
                    <td>
                      Ensure adequate temperature control and protection against
                      thermal runaway
                    </td>
                    <td></td>
                    <td>
                      Ventilated, explosion-proof electrical, thermostatic
                      control to maintain and record the temperature at 75 ±
                      2°C, protection against thermal run-away if the thermostat
                      malfunctions (independent thermal cutoff)
                    </td>
                  </tr>
                  <tr>
                    <td>Self-heating temperature change</td>
                    <td>
                      Parameter for characterizing the thermal stability of an
                      article
                    </td>
                    <td></td>
                    <td>3°C or greater</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Ignition or explosion</td>
                    <td>Visual post-test inspection</td>
                    <td>Damage to oven, flame traces, etc.</td>
                  </tr>
                  <tr>
                    <td>Ignition or explosion</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Damage to sample holder, flame traces, consumption of
                      sample, etc. Mass explosion hazard [IP Division 1.1]
                    </td>
                  </tr>
                  <tr>
                    <td>Article temperature</td>
                    <td>Thermocouple log</td>
                    <td>
                      Difference between oven and article of 3°C or greater is
                      evidence of self-heating. [IP Division 1.1]
                    </td>
                  </tr>
                  <tr>
                    <td>Article casing or outer package damage</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Damage to unpackaged article or outer package. [IP
                      Division 1.1]
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dangerous [subjective] exudation of explosive substance
                    </td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Explosive visible outside the article. [IP Division 1.1]
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">*OR relationship</p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2">
            <TitleComponent
              class="pt-8"
              title="No-Go Example Plot"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/nogoplot-min.png')"
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/IPSeries/nogoplot-min.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
